<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" scrollable max-width="370px">
                <v-card>
                    <v-card-title>
                        <span class="subtitle-1"><v-icon left>mdi-format-list-checkbox</v-icon> Select columns to display</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text style="height: 350px;">
                        <v-checkbox 
                        v-for="(item, index) in this.columns" 
                        :key="index"
                        v-model="selectedItems" 
                        :label="item.text" 
                        :value="item.value">
                        </v-checkbox>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                    <v-btn color="success" text @click="save()">Save</v-btn>
                    <v-btn color="error" text @click="cancel()">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'

export default {
    name: 'data-table-toggle-columns',
    props: ['dataTableId', 'columns', 'dataTableColumnsMenuId'],
    data: () => ({
        dialog: false,
        selectedItems: []
    }),

    methods: {
        save() {
            //window.console.log(this.selectedItems)
            EventBus.$emit(this.dataTableId, {
                dataTableHeaders: this.selectedItems
            })

            this.dialog = false
        },

        cancel() {
            this.dialog  = false

            var vm = this
            if(vm.columns != null && vm.columns.length > 0) {
                    this.columns.forEach(column => {
                    if(column.visibility) {
                        vm.selectedItems.push(column.value)
                    }
                })
            }
        }
    },

    created() {
        var vm = this
        EventBus.$on(this.dataTableColumnsMenuId, (data) => {
            if(data.toggleColumnsMenu) {
                setTimeout(() => {
                    vm.dialog = true
                }, 10)
            }
        })

        if(vm.columns != null && vm.columns.length > 0) {
                this.columns.forEach(column => {
                if(column.visibility) {
                    vm.selectedItems.push(column.value)
                }
            })
        }
    },

    mounted() {
        
    },

    destroyed() {

    }
}

</script>

<style scoped>

</style>